@use '@styles/main.scss';
@use '@styles/abstracts/_variables.scss' as var;

h1 {
  color: royalblue;
}

.home-image {
  width: 320px;
}
