// Base
// @use 'base/normalize';
// // @use 'base/space';
// // @use 'base/layout';

// // App font: load your font
// //@use 'base/fonts/arial' as font;
// //@use 'base/fonts/helvetica' as font;
// @use 'base/fonts/roboto' as font;

// @use 'base/typography' with (
//   $font-family: font.$font-family,
// );

// // Icons
// @use 'base/mat-icons';

// // Page layout: header, main, footer
// @use 'pages/default-layout';


// :copyright: (c) 2014-2024 Allenta Consulting, S.L. <info@allenta.com>

$brand-primary: #426749;

$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
// ************************************************************************************************************************************************************
//  Media query helper mixin.
@mixin media($device1, $device2: xx) {
  @if ($device1 == md and $device2 == lg) or ($device1 == lg and $device2 == md) {
    @media (min-width: $screen-md-min)  { @content; }
  }@else if ($device1 == sm and $device2 == md) or ($device1 == md and $device2 == sm) {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) { @content; }
  }@else if ($device1 == sm and $device2 == xs) or ($device1 == xs and $device2 == sm) {
    @media (max-width: $screen-sm-max)  { @content; }
  }@else if $device1 == lg {
    @media (min-width: $screen-lg-min) { @content; }
  }@else if $device1 == md {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content; }
  }@else if $device1 == sm {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content; }
  }@else if $device1 == xs {
    @media (max-width: $screen-xs-max)  { @content; }
  }
}

// ************************************************************************************************************************************************************
// Font definitions.

// Google fonts.
@import url(//fonts.googleapis.com/css?family=Raleway:400|Open+Sans:400,700|Material+Icons);

// Bootstrap main font variables. (see main/sites/all/libraries/bootstrap-sass-official/stylesheets/bootstrap/_variables.scss)
$font-family-sans-serif:  "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base:        $font-family-sans-serif;
$font-size-base:          13px;
$headings-font-family:    "Raleway", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

// Material Icons.
.icon {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
// ************************************************************************************************************************************************************


html * {
  box-sizing: border-box;
}
body {
  font-family: "Roboto Flex", BlinkMacSystemFont, Segoe UI, Roboto,Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei,SimSun, sans-serif;
  font-size: 16px;
  max-width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  background: #fff;

  &[lang="es"] {
    .language-switcher a[data-lang="es"],
    .i18n.en {
      display: none !important;
    }
  }
  &[lang="en"] {
    .language-switcher a[data-lang="en"],
    .i18n.es {
      display: none !important;
    }
  }
}

a, a:hover, a:link, a:visited {
  color: $brand-primary;
  text-decoration: none;
  transition: color 0.8s;
}

// ******************************************************************************
//  Main container
// ******************************************************************************
#main-container {
  width: 100%;
  max-width: 1280px;
  min-height: 100%;
  padding: 0;
  background: #fff;
}

// ******************************************************************************
//  Main header
// ******************************************************************************
.main-header {
  position: relative;
  padding-top: 15px;
  transition: all 0.5s;
  .wrapper {
    .logo {
      width: 200px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .language-switcher {
      position: absolute;
      top: 50px;
      left: 50px;
      @include media(xs) {
        top: 15px;
        left: 15px;
      }
    }
  }
}

// ******************************************************************************
//  Main content
// ******************************************************************************
.main-content {
  .main-blocks {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .block-wrapper {
      display: flex;
      align-items: stretch;
      .block {
        flex: 1 0 50%;
      }
      @include media(xs) {
        flex-direction: column;
        .block {
          flex: 1 0 100%;
        }
      }
    }
  }
}

// ****************** Block style ******************
.block {
  $block-height: 400px;
  $block-height-sm: $block-height; // 600px;
  $block-height-lg: $block-height; // 700px;
  position: relative;
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  .block-image {
    overflow: hidden;
    width: 100%;
    height: 100%;

    .inner {
      width: 100%;
      height: $block-height;
      background-size: cover;
    }
  }
  &.first {
    .block-image .inner {
      background: url('../images/centro-1280.jpg') no-repeat 50% 30%; // h v
      // background-size: 100% auto;
    }
  }
  &.second {
    .block-image .inner {
      background: url('../images/trofeo-767-2024.jpg') no-repeat 50% 0%;
      // background-size: 100% auto;
    }
  }
  &.third {
    .block-image .inner {
      background: url('../images/csi-767-2024.jpg') no-repeat 100% 0;
      // background-size: 100% auto;
    }
  }
  .block-text {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 30px;
    text-align: center;
    color: #fff;
    background: rgba($brand-primary, 0.5);
    h2.block-title {
      font-size: 26px;
      font-weight: normal;
      font-family: $headings-font-family;
      span {
        display: block;
        font-size: 38px;
      }
      .icon {
        font-size: 16px;
      }
    }
  }
  // Hover scale up effect.
  .block-image .inner,
  .block-text .block-title {
    transition: transform 0.5s ease-out;
  }
  &:hover {
    .block-image .inner,
    .block-text .block-title {
      transform: scale(1.07);
    }
  }
}

// ******************************************************************************
//  Main footer
// ******************************************************************************
.main-footer {
  padding-top: 40px;
  text-align: center;
  ul {
    padding: 0;
    margin-top: 30px;
    margin-bottom: 30px;
    li {
      list-style-type: none;
    }
  }
}
